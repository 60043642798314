import React, { useState, useEffect, useRef, useContext } from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import third from '../Component/Image/third.jpg'
import second from '../Component/Image/first.jpg'
import first from '../Component/Image/first.jpg'
import kant from '../Component/Image/(5).jpg'
import Vibes from '../Component/Image/(5).jpg'
import { FaLocationDot } from "react-icons/fa6";
import { BsTrainFront } from "react-icons/bs";
import { LuFuel } from "react-icons/lu";
import { FaCalendarAlt } from "react-icons/fa";
import right from "../Component/Image/right.png";
import left from "../Component/Image/left.png"
import { GiPeaks } from "react-icons/gi";
import { Modal, Button } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';


import { LuPlane } from "react-icons/lu";

import Header from './Header';
import './Header.css';
import ComingSoon from './Comming';
import { useNavigate, useParams } from 'react-router-dom';
import Footer from './Footer';
import Context from '../Context/Context';


const BaseCamp = () => {

  const { id } = useParams();
  const { user, setUser } = useContext(Context);
  const [Data, setData] = useState();
  const [HeaderImages, setHeaderImages] = useState([])
  const [Top, setTop] = useState();
  const [Top_first, setTop_first] = useState();
  const [top_second, setTop_second] = useState();
  const [Gallery, setGallery] = useState([]);
  const [moreData, setMoreData] = useState()
  const galleryContainerRef = useRef(null);
  const galleryContainerRef1 = useRef(null);
  const [colWidth, setColWidth] = useState(0);
  const [numColumns, setNumColumns] = useState(4);
  const [colWidth1, setColWidth1] = useState(0);
  const [numColumns1, setNumColumns1] = useState(4);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [loader, setLoader] = useState(true);
  const [ShortImage, setShortImage] = useState();
  const [numberOfTravelers, setNumberOfTravelers] = useState('');
  const [timeInHand, setTimeInHand] = useState('');
  const [tripType, setTripType] = useState('');
  const [tripLocation, setTripLocation] = useState('');
  const [overallBudget, setOverallBudget] = useState('');
  const [travelMedium, setTravelMedium] = useState('');
  const [mobileNumbers, setMobileNumbers] = useState('');
  const [ImageBaseUrl, setImageBaseUrl] = useState('');

  const navigate = useNavigate();
  const [showFullContent, setShowFullContent] = useState(false);

  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  };

  const [showFullContent1, setShowFullContent1] = useState(false);

  const toggleContent1 = () => {
    setShowFullContent1(!showFullContent1);
  };

  const handleNumberOfTravelersChange = (event) => {
    setNumberOfTravelers(event.target.value);
};

const handleTimeInHandChange = (event) => {
    setTimeInHand(event.target.value);
};

const handleTripTypeChange = (event) => {
    setTripType(event.target.value);
};

const handleTripLocationChange = (event) => {
    setTripLocation(event.target.value);
};

const handleOverallBudgetChange = (event) => {
    setOverallBudget(event.target.value);
};

const handleTravelMediumChange = (event) => {
    setTravelMedium(event.target.value);
};

const handleMobileNumbersChange = (event) => {
    setMobileNumbers(event.target.value);
};



const handleFormSubmit = () => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  const urlencoded = new URLSearchParams();
  urlencoded.append("travellers", numberOfTravelers);
  urlencoded.append("time_hand", timeInHand);
  urlencoded.append("trip_type", tripType);
  urlencoded.append("trip_location", tripLocation);
  urlencoded.append("budget", overallBudget);
  urlencoded.append("travel_medium", travelMedium);
  urlencoded.append("mobile_no", mobileNumbers);

  const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow"
  };

  fetch("https://kalyanmilan.online/api/inquiries", requestOptions)
      .then((response) => response.json())
      .then((result) => {
          console.log(result);
          clearForm(); 
          toast.success("Form submitted successfully!");// Log the API response
          // Handle any further actions based on API response
      })
      .catch((error) => console.error(error));
};
  const handleNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === Gallery.length - 1 ? 0 : prevIndex + 1));
  };

  const handlePrevious = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? Gallery.length - 1 : prevIndex - 1));
  };

  const openModal = (image) => {
    setShowModal(true)
    setSelectedImage(image);
  };

  const closeModal = () => {
    setShowModal(false)
    setSelectedImage(null);
  };
  useEffect(() => {
    // Calculate the width of a single col-lg-3 element
    if (galleryContainerRef.current) {
      const col = galleryContainerRef.current.querySelector('.col-lg-3');
      if (col) {
        const width = col.offsetWidth;
        setColWidth(width);
      }
    }
    // Calculate the number of columns for mobile
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 768) {
        setNumColumns(1);
      } else {
        setNumColumns(4);
      }
    };
    handleResize(); // Call once to set initial number of columns
    window.addEventListener('resize', handleResize); // Add event listener for window resize
    return () => window.removeEventListener('resize', handleResize); // Remove event listener on component unmount
  }, [Gallery]);


  useEffect(() => {
    // Calculate the width of a single col-lg-3 element
    if (galleryContainerRef1.current) {
      const col = galleryContainerRef1.current.querySelector('.col-lg-3');
      if (col) {
        const width = col.offsetWidth;
        setColWidth1(width);
      }
    }
    // Calculate the number of columns for mobile
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 768) {
        setNumColumns1(1);
      } else {
        setNumColumns1(4);
      }
    };
    handleResize(); // Call once to set initial number of columns
    window.addEventListener('resize', handleResize); // Add event listener for window resize
    return () => window.removeEventListener('resize', handleResize); // Remove event listener on component unmount
  }, [moreData]);

  const handleScroll = (scrollOffset) => {
    galleryContainerRef.current.scrollLeft += scrollOffset * colWidth * numColumns;
  };

  const handleScroll1 = (scrollOffset) => {
    galleryContainerRef1.current.scrollLeft += scrollOffset * colWidth1 * numColumns1;
  };




  const Get_Api = () => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    fetch(`https://kalyanmilan.online/api/getPost/${id}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        setData(result.data);
        setMoreData(result)
        const filteredImages = result.data.images?.filter(image => image.image_type == "header");
        const filteredImages1 = result.data.images?.filter(image => image.image_type == "top");
        const filteredImages2 = result.data.images?.filter(image => image.image_type == "top_first");
        const filteredImages3 = result.data.images?.filter(image => image.image_type == "top_second");
        const filteredImages4 = result.data.images?.filter(image => image.image_type == "gallery");
        const filteredImages5 = result.data.images?.filter(image => image.image_type == "short");
        console.log(filteredImages);
        setTop(filteredImages1[0]?.img_path);
        setTop_first(filteredImages2[0]?.img_path);
        setTop_second(filteredImages3[0]?.img_path);
        setGallery(filteredImages4)
        setHeaderImages(filteredImages);
        setShortImage(filteredImages5[0]?.img_path);
        setLoader(false)
        window.scrollTo(0, 0);
        setImageBaseUrl(result?.image_url)
      })
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    Get_Api();
  }, [user])


  const renderCustomIndicator = (clickHandler, isSelected, index, label) => {
    const indicatorStyle = {
      width: '20px',
      height: '20px',
      margin: '0 5px',
      background: isSelected ? '#fff' : '#888', // Change the background color based on selection
      borderRadius: '10%',
      cursor: 'pointer',
      display: 'inline-block',
    };

    return (
      <>
      <li key={index}  onClick={clickHandler} role="button" style={indicatorStyle} tabIndex={0} title={`${label} ${index + 1}`} />
      </>
    );
  };



  function truncateText(text, maxLength) {
    if (text.length <= maxLength) return text;
    return text.substr(0, maxLength) + '...';
  }

  function truncateText1(text, maxLength) {
    if (text.length <= maxLength) return text;
    return text.substr(0, maxLength) + '...';
  }


  let data = [
   "#DBECF1",
    "#EBF1DB",
    "#F6E9E9",
    "#EDE9F6"
    ]

  function getRandomColor() {
    const randomIndex = Math.floor(Math.random() * data.length); // Generate random index
    return data[randomIndex]; // Return the color at the random index
  }

  const randomColor = getRandomColor();
  const randomColor1 = getRandomColor();
  const randomColor2 = getRandomColor();
  const randomColor3 = getRandomColor();
  const randomColor4 = getRandomColor();


  const clearForm = () => {
    setNumberOfTravelers('');
    setTimeInHand('');
    setTripType('');
    setTripLocation('');
    setOverallBudget('');
    setTravelMedium('');
    setMobileNumbers('');
};



  return (
    <>
      <Header />

      <ToastContainer position="bottom-center" autoClose={3000} hideProgressBar={false} />

       {
         loader?<>
            <h1>loading....</h1>
         </>:<>
         <section>
        <div className='container-fluid' style={{marginTop:75}}>
          <div className='row'>
            <div className='col-lg-12 col-sm-12'>

              <Carousel autoPlay infiniteLoop interval={3000} showThumbs={false} showIndicators={true} renderIndicator={renderCustomIndicator}>
                {
                  HeaderImages?.map((res) => {
                    return (
                      <>
                        <div>
                          <img src={res.img_path} alt='img' style={{ height: "100%" }} />
                          <h1 style={{ backgroundColor: 'transparent', fontSize: '28px',}} className="legend">{Data?.post_title} <br /></h1>
                        </div>
                      </>
                    )
                  })
                }

              </Carousel>
            </div>
          </div>
        </div>
      </section>



      <section>
      <div className='container p-5 mt-4 hii' style={{ backgroundColor: '#e9e9e98a' }}>
        <div className='row'>
          <div className='col-lg-6 col-md-6 col-sm-12'>
            <h3 style={{ fontWeight: '700', textShadow: '1.1px 0px 0px #c1c1c1' }}>
              {Data?.post_up_title}
            </h3>
            <div>
              <div dangerouslySetInnerHTML={{ __html: showFullContent ? Data?.post_up_fst_desc : truncateText(Data?.post_up_fst_desc, 840) }} />
             
              {!showFullContent && Data?.post_up_fst_desc && Data?.post_up_fst_desc.length > 840 && <span className='mb-5'>
                                <i>
                                    <strong className='text-info' style={{ cursor: 'pointer' }} onClick={toggleContent}>Read More</strong>
                                </i>
                            </span> }
            </div>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12 mt-2'>
            <img src={Top_first} alt='image' style={{ width: '100%' }} />
          </div>
        </div>
      </div>
    </section>

      <section  >
        <div className='container px-5 hii' style={{ backgroundColor: '#e9e9e98a',paddingBottom:30 }}>
          <div className='row'>
            <div className='col-lg-6 col-md-6 col-sm-12 mt-3 '>
              <img src={top_second} alt='image' style={{ width: '100%', }} />
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12 mt-2'>
              <div>
              <div dangerouslySetInnerHTML={{ __html: showFullContent1 ? Data?.post_up_sec_desc : truncateText1(Data?.post_up_sec_desc, 900) }} />
              {/* {!showFullContent1 && <button className='border-0 btn btn-link'  style={{justifyContent:"left",textAlign:"left",marginTop:0}} onClick={toggleContent1}>Read More</button>} */}
              {!showFullContent1 && Data?.post_up_sec_desc && Data?.post_up_sec_desc?.length > 900 && <span className='mb-5'>
                  <i>
                      <strong className='text-info' style={{ cursor: 'pointer' }} onClick={toggleContent1}>Read More</strong>
                  </i>
              </span> }
            </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='container mt-4 px-5  p-3 hii' style={{ backgroundColor:  "#DBECF1" }}>
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12'>
              <h2 className='my-4' style={{ fontWeight: '700', textShadow: '1.1px 0px 0px #c1c1c1', }}>
                {/* How to reach Panchachuli Base camp with the minimum facility */}
                {
                  Data?.post_mid_title
                }
              </h2>

              {
                <div dangerouslySetInnerHTML={{ __html: Data?.post_mid_description }} />

              }

            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='container px-5 bg-light mt-4' style={{paddingBottom:30}}>
          <div className='row'>

            <h2 className='p-3' style={{ fontWeight: '700', textShadow: '1.1px 0px 0px #c1c1c1' }}>
              {
                Data?.post_short_title
              }
            </h2>
            <div className='col-lg-6 col-md-6 col-sm-12  mt-3'>
              <div className='card' style={{ fontSize: '20px', textShadow: '1.1px 0px 0px #c1c1c1', backgroundColor: '#e9e9e98a', border: 'none' }}>
                <div className='card-body'>

                  {
                    Data?.short_keys?.map((res) => {
                      return (
                        <>
                          <div className='row mt-3'>
                            <div className='col-1 mt-1'>
                              {
                                res?.vector_image?.imgpath?<img src={`${ImageBaseUrl}/${res?.vector_image?.imgpath}`} alt='#' style={{maxWidth:"100%"}}/>: <GiPeaks className=' abc me-3 ' style={{ fontSize: '26px', color: 'orange', }} />
                              }
                             
                            </div>
                            <div className='col-11'>
                              <strong style={{ fontSize: '24px' }}>{res?.name} :  <span className='  ms-3 ' style={{ fontSize: '25px', fontWeight: '400' }}> {res?.value}</span></strong>

                            </div>
                          </div>
                        </>
                      )
                    })
                  }


                </div>
              </div>
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12 mt-3    '>
              <img src={ShortImage} alt='image' style={{ width: '100%' }} />
            </div>
          </div>
        </div>
      </section>


      <section>
        <div className='container px-5 p-4 mt-4' style={{ backgroundColor: "#EBF1DB" }}>
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12'>
            <h2 className='my-4' style={{ fontWeight: '700', textShadow: '1.1px 0px 0px #c1c1c1',textTransform:"capitalize"}}>
                {/* How to reach Panchachuli Base camp with the minimum facility */}
                {
                  Data?.places_visit_title
                }
              </h2>
              <div dangerouslySetInnerHTML={{ __html: Data?.post_expolore_section }} />

            </div>
          </div>
        </div>
      </section>


      <section className='mt-4'>
        <div className='container px-5 p-5 hii' style={{ backgroundColor: "#F6E9E9" }}>
          <div className='row'>

            <div className='col-lg-12 col-md-12 col-sm-12'>
            <h2 className='my-4' style={{ fontWeight: '700', textShadow: '1.1px 0px 0px #c1c1c1',textTransform:"capitalize"}}>
                {/* How to reach Panchachuli Base camp with the minimum facility */}
                {
                  Data?.things_to_do_title
                }
              </h2>

              <div dangerouslySetInnerHTML={{ __html: Data?.post_activity_section }} />


            </div>
          </div>
        </div>
      </section>

      {/* slider start */}



<section className='mt-4'>
        <div className='container px-5 p-5 hii' style={{ backgroundColor: '#e9e9e98a' }}>
          <div className='row'>
            <h2 style={{ fontWeight: '700', textShadow: '1.1px 0px 0px #c1c1c1' }}>{Data?.photo_galary_title}</h2>
            <div className={`col-lg-12 col-md-12 col-sm-12 d-flex ${numColumns === 1 ? 'flex-wrap' : ''}`} style={{ overflowX: "scroll" }} ref={galleryContainerRef}>
              {Gallery.map((res, index) => {
                return (
                  <>
                    <div key={index} className={`col-lg-${12 / numColumns} col-md-12 col-sm-12 d-flex`}>
                      
                                    <img onClick={()=>{setShowModal(true);setSelectedImage(res?.img_path);setCurrentImageIndex(index)}} className='p-3 w-100' src={res.img_path} alt='image' style={{  height: "300px",cursor:"pointer",objectFit:"cover",borderRadius:30}} />
                                   

                        
                    </div>


                  </>

                )
              }


              )}


            </div>
          </div>
          <div className="mt-3 d-flex" style={{ justifyContent: "center", alignItems: "center" }}>
            {/* <button onClick={() => handleScroll(-100)}>Previous</button> */}

            <img src={left} onClick={() => handleScroll(-450)} style={{ cursor: "pointer", marginRight: 20 }} />
      <img src={right} onClick={() => handleScroll(450)} style={{ cursor: "pointer", marginRight: 0 }} />
            {/* <button >Next</button> */}
          </div>
        </div>


      </section>


      <section>
        <div className='container px-5 p-4 mt-4' style={{ backgroundColor: "#EDE9F6" }}>
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12'>

              <div style={{fontSize:22,fontWeight:400,textShadow: '1.1px 0px 0px #c1c1c1'}} dangerouslySetInnerHTML={{ __html: Data?.post_consider_section  }} />

            </div>
          </div>
        </div>
      </section>
{/* 
      <section className='mt-4'>
        <div className='container  p-5 hii' style={{ backgroundColor: '#e9e9e98a' }}>
          <div className='row'>

            <div className='col-lg-12 col-md-12 col-sm-12' >

              <div dangerouslySetInnerHTML={{ __html: Data?.post_consider_section }} />


            </div>
          </div>
        </div>
      </section> */}


      <section className='mt-4'>
        <div className='container px-5 p-5 hii' style={{ backgroundColor: '#e9e9e98a' }}>
          <div className='row'>
            <h2 style={{ fontWeight: '700', textShadow: '1.1px 0px 0px #c1c1c1' }}>{Data?.post_explore_more_title}</h2>
            <div className={`col-lg-12 col-md-12 col-sm-12 d-flex ${numColumns1 === 1 ? 'flex-wrap' : ''}`} style={{ overflowX: "scroll" }} ref={galleryContainerRef1}>
              {moreData?.expolore_more_post?.map((res, index) => {
                return (
                  <>
                    <div key={index} className={`col-lg-${12 / numColumns1} col-md-12 col-sm-12 d-flex`} style={{cursor:"pointer"}}>
                      {
                        res?.images?.map((res1) => {
                          return (
                            <>
                              {
                                res1?.image_type === "top" ?
                                  <div onClick={()=>{navigate(`/${res?.post_slug}`);setUser(true);}}>
                                    <img className='p-3' src={res1.img_path} alt='image' style={{ width: '100%', height: "300px",borderRadius:30 }} />
                                    <div className='container d-flex' style={{justifyContent:"center",alignItems:"center"}}>
                                    <h1 style={{ backgroundColor: 'transparent', fontSize: '20px',textShadow: '1.1px 0px 0px #c1c1c1'  }} className="legend">{moreData?.expolore_more_post[index]?.post_title} <br /></h1>
                                    </div>
                                  </div>
                                  : null
                              }
                            </>
                          )
                        })
                      }
                    </div>


                  </>

                )
              }


              )}


            </div>
          </div>
          <div className="mt-3 d-flex" style={{ justifyContent: "center", alignItems: "center" }}>
            {/* <button onClick={() => handleScroll(-100)}>Previous</button> */}

            <img src={left} onClick={() => handleScroll1(-100)} style={{ cursor: "pointer", marginRight: 20 }} />
            <img src={right} onClick={() => handleScroll1(100)} style={{ cursor: "pointer", marginRight: 0 }} />
            {/* <button >Next</button> */}
          </div>
        </div>


      </section>


      <section className='mt-4'>
        <div className='container px-5 p-5 hii' style={{ backgroundColor: '#e9e9e98a' }}>
          <div className='row'>
            <h2 style={{ fontWeight: '700', textShadow: '1.1px 0px 0px #c1c1c1' }}>{Data?.trending_destination_title}</h2>
            <div className={`col-lg-12 col-md-12 col-sm-12 d-flex ${numColumns1 === 1 ? 'flex-wrap' : ''}`} style={{ overflowX: "scroll" }} ref={galleryContainerRef1}>
              {moreData?.trending_more_post?.map((res, index) => {
                return (
                  <>
                    <div key={index} className={`col-lg-${12 / numColumns1} col-md-12 col-sm-12 d-flex`} style={{cursor:"pointer"}}>
                      {
                        res?.images?.map((res1) => {
                          return (
                            <>
                              {
                                res1?.image_type === "top" ?
                                  <div onClick={()=>{navigate(`/${res?.post_slug}`);setUser(true);}}>
                                    <img className='p-3' src={res1.img_path} alt='image' style={{ width: '100%', height: "300px",borderRadius:30 }} />
                                    <div className='container d-flex' style={{justifyContent:"center",alignItems:"center"}}>
                                    <h1 style={{ backgroundColor: 'transparent', fontSize: '20px',textShadow: '1.1px 0px 0px #c1c1c1'  }} className="legend">{moreData?.expolore_more_post[index]?.post_title} <br /></h1>
                                    </div>
                                  </div>
                                  : null
                              }
                            </>
                          )
                        })
                      }
                    </div>


                  </>

                )
              }


              )}


            </div>
          </div>
          <div className="mt-3 d-flex" style={{ justifyContent: "center", alignItems: "center" }}>
            {/* <button onClick={() => handleScroll(-100)}>Previous</button> */}

            <img src={left} onClick={() => handleScroll1(-100)} style={{ cursor: "pointer", marginRight: 20 }} />
            <img src={right} onClick={() => handleScroll1(100)} style={{ cursor: "pointer", marginRight: 0 }} />
            {/* <button >Next</button> */}
          </div>
        </div>


      </section>

      <section className='mt-4'>
        <div className='container px-5 p-5 hii' style={{ backgroundColor: '#DBECF1' }}>
          <div className='row'>
            <h2 style={{ fontWeight: '700', textShadow: '1.1px 0px 0px #c1c1c1' }}>Faq Asked Questions</h2>
            {/* <ComingSoon/> */}
            <div className='col-lg-12 col-md-12 col-sm-12 mt-4'>

              <div style={{ lineHeight: '32px' }}>
                <div className="accordion" id="accordionExample">

                  {
                    Data?.question_keys?.map((res, index) => {
                      return (
                        <div className="accordion-item border-2 my-2 " style={{ borderRadius: '8px', backgroundColor: 'transparent',cursor:"pointer" }}>
                          <div className='d-flex'>
                          <h2 data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} style={{marginLeft:15,marginTop:5}}>+</h2>
                          <h2 className="accordion-header" id="headingThree">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="false" aria-controls={`collapse${index}`}
                              style={{ fontSize: '18px', fontWeight: '600', borderRadius: '8px', backgroundColor: 'transparent', boxShadow: 'none' }}>
                              {res?.name}
                            </button>
                          </h2>
                          </div>
                          <div id={`collapse${index}`} className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                              <h6 style={{ fontSize: '18px' }}>{res?.value}</h6>
                            </div>
                          </div>
                        </div>
                      )
                    }
                    )
                  }


                </div>
              </div>

            </div>
          </div>
        </div>
      </section>


      <section>
        <div className='container px-5 my-4'  style={{ backgroundColor: '#EDE9F6' }}>

          <div className='row'>
            <div className='col-lg-7 col-md-6 col-sm-12 mt-4'>
              <div dangerouslySetInnerHTML={{ __html: Data?.post_plan_section }} />
            </div>
           

            <div className='col-lg-5 col-md-6 col-sm-12 mt-4 p-4 jkl'>


            <div className='card-body'>
            <div className="mb-3">
                <input
                    type="text"
                    className="form-control py-3"
                    placeholder='Number Of Travelers'
                    value={numberOfTravelers}
                    onChange={handleNumberOfTravelersChange}
                    style={{ fontSize: '20px', textShadow: '1.1px 0px 0px #c1c1c1' }}
                />
            </div>

            <div className="mb-3">
                <input
                    type="text"
                    className="form-control py-3"
                    placeholder='Time in Hand (Days & Nights)'
                    value={timeInHand}
                    onChange={handleTimeInHandChange}
                    style={{ fontSize: '20px', textShadow: '1.1px 0px 0px #c1c1c1' }}
                />
            </div>

            <div className="mb-3">
                <input
                    type="text"
                    className="form-control py-3"
                    placeholder='Trip Type (Trek/Adventure/etc)'
                    value={tripType}
                    onChange={handleTripTypeChange}
                    style={{ fontSize: '20px', textShadow: '1.1px 0px 0px #c1c1c1' }}
                />
            </div>

            <div className="mb-3">
                <input
                    type="text"
                    className="form-control py-3"
                    placeholder='Trip Location (Ex- Rishikesh)'
                    value={tripLocation}
                    onChange={handleTripLocationChange}
                    style={{ fontSize: '20px', textShadow: '1.1px 0px 0px #c1c1c1' }}
                />
            </div>

            <div className="mb-3">
                <input
                    type="text"
                    className="form-control py-3"
                    placeholder='Overall Budget (INR)'
                    value={overallBudget}
                    onChange={handleOverallBudgetChange}
                    style={{ fontSize: '20px', textShadow: '1.1px 0px 0px #c1c1c1' }}
                />
            </div>

            <div className="mb-3">
                <input
                    type="text"
                    className="form-control py-3"
                    placeholder='Travel Medium (Air/Rail/Road/All)'
                    value={travelMedium}
                    onChange={handleTravelMediumChange}
                    style={{ fontSize: '20px', textShadow: '1.1px 0px 0px #c1c1c1' }}
                />
            </div>

            <div className="mb-3">
                <input
                    type="text"
                    className="form-control py-3"
                    placeholder='Mobile numbers'
                    value={mobileNumbers}
                    onChange={handleMobileNumbersChange}
                    style={{ fontSize: '20px', textShadow: '1.1px 0px 0px #c1c1c1' }}
                />
            </div>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <button
                    type="button"
                    onClick={handleFormSubmit}
                    style={{
                        width: '70%',
                        backgroundColor: '#303091',
                        border: '2px solid #5bc1b8',
                        padding: '8px 14px',
                        borderRadius: '8px',
                        fontSize: '22px',
                        color: '#fff'
                    }}
                >
                    Send Enquiry
                </button>
            </div>
            </div>


              {/* <div className='card' style={{ backgroundColor: '#e9e9e98a' }}>
                <div className='card-body'>


                  <div class="mb-3">

                    <input type="email" class="form-control py-3" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Number Of Travelers'
                      style={{ fontSize: '20px', textShadow: '1.1px 0px 0px #c1c1c1' }} />
                  </div>


                  <div class="mb-3">
                    <input type="email" class="form-control py-3" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Day In hand' style={{ fontSize: '20px', textShadow: '1.1px 0px 0px #c1c1c1' }} />
                  </div>


                  <div class="mb-3">
                    <input type="email" class="form-control py-3" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Preferrable Location' style={{ fontSize: '20px', textShadow: '1.1px 0px 0px #c1c1c1' }} />
                  </div>

                  <div class="mb-3">
                    <input type="email" class="form-control py-3" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Trek type ' style={{ fontSize: '20px', textShadow: '1.1px 0px 0px #c1c1c1' }} />
                  </div>

                  <div class="mb-3">
                    <input type="email" class="form-control py-3" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder=' Budget per Travel ' style={{ fontSize: '20px', textShadow: '1.1px 0px 0px #c1c1c1' }} />
                  </div>

                  <div class="mb-3">
                    <input type="email" class="form-control py-3" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Mobile Number ' style={{ fontSize: '20px', textShadow: '1.1px 0px 0px #c1c1c1' }} />
                  </div>

                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <button type="button" style={{ width: '70%', backgroundColor: '#303091', border: '2px solid #5bc1b8', padding: '8px 14px', borderRadius: '8px', fontSize: '22px', color: '#fff' }}>Send Enquiry</button>
                  </div>

                </div>
              </div> */}

            </div>

          </div>
        </div>
      </section>
      {selectedImage && (
  <Modal
    show={showModal}
    onHide={closeModal}
    size="fullscreen"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }} // Adjust alpha value for transparency
  >
    <Modal.Header closeButton style={{ height: 20 }}>
      <div className="d-flex" style={{ justifyContent: "space-between", alignItems: "center", width: '100%' }}>
        <div className='col-5'></div>
        <div className='d-flex col-2' style={{ justifyContent: "space-between", alignItems: "center" }}>
          <button className='border-0' onClick={handlePrevious} style={{color:"#007bff"}}>Previous</button>
          <div>{currentImageIndex + 1}/{Gallery.length}</div>
          <button className='border-0' onClick={handleNext} style={{color:"#007bff"}}>Next</button>
        </div>
        <div className='col-5'></div>
      </div>
    </Modal.Header>
    <Modal.Body style={{ width: "100%", backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>
      <div className='container'>
        <div className='row' style={{ justifyContent: "center", alignItems: "center" }}>
          <img className='p-3' src={Gallery[currentImageIndex]?.img_path} alt='image' style={{ width: 'auto' }} />
        </div>
      </div>
    </Modal.Body>
  </Modal>
)}

         </>
       }
    


     <Footer/>
    </>
  )
}

export default BaseCamp