import React, { useContext, useEffect, useState } from 'react'
import { CiFacebook } from "react-icons/ci";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import logo from "../../src/Component/Image/Logo.png"
import { useNavigate } from 'react-router-dom';
import Context from '../Context/Context';
import { FaFacebook } from 'react-icons/fa6';

const Footer = () => {
  const { user, setUser } = useContext(Context);

  const [data, setData] = useState([]);

  const Navigate = useNavigate();


  useEffect(() => {
      getApi();
  }, [])
  

  const getApi = ()=>{
    const requestOptions = {
      method: "GET",
      redirect: "follow"
    };
    
    fetch("https://kalyanmilan.online/api/getfooter", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result,"jjjjjjjj=>");
        setData(result?.data)
      })
      .catch((error) => console.error(error));
  }

  return (
    <div>

        <section className='py-3' style={{backgroundColor:'#e1eff9'}}>
        <div className='container my-4'> 
            <div className='row'>
                <div className='col-lg-3 col-md-6 col-dm-12 mt-3' >
       <div style={{backgroundColor:"#fff",cursor:"pointer"}} onClick={()=>{Navigate(`/Home/46`)}}>
        {/* <h2>{}</h2> */}
        <img src={logo} style={{width:"100%",height:"150px",objectFit: "cover"}}/>
        
       </div>
       <div className='mt-2 p-2' style={{backgroundColor:"#fff",display:"flex",alignItems:"center",justifyContent:"center",textAlign:"center"}}>
          <h5>An Encyclopedia on Tourism in India</h5>
        </div>



       <div  className="mt-4 mb-3 col-12" style={{display:'inline-flex',justifyContent:"space-between",  }}>
        <a href='https://www.facebook.com/placestovisitIndiaofficial'>
       <FaFacebook  style={{fontSize:'30px'}}/>
       </a>
       <a href='https://www.instagram.com/placestovisitindiaofficial/'>
       <FaInstagram className="mx-2" style={{fontSize:'28px'}}/>
       </a>
       <a href="https://www.youtube.com/channel/UC9nYP8zZXGFlEmA2gIS1SVA">
       <FaYoutube  className="mx-2"style={{fontSize:'28px'}}/>
       </a>
       <a href='https://twitter.com/Places2visitind'>
       <FaTwitter  className="mx-2" style={{fontSize:'28px'}}/>
       </a>
       </div>

       <h5>Write to us: <a href='mailto:info@placestovisitindia.com'>info@placestovisitindia.com</a></h5>

                </div>
                  
                  {
                    data?.slice(0, 5)?.map((res)=>{
                      return(
                        <>
                         <div className='col-lg-3 col-md-6 col-dm-12 mt-3'>
                 
                 <h5 style={{fontWeight:'700'}} className='mb-4'>{res?.name}</h5>
                 {
                  res?.onlyposts?.map((res)=>{
                    return(
                      <>
                      <ul onClick={()=>{Navigate(`/${res?.post_slug}`);
                                          setUser(true)
                                        }} className='ps-0' style={{textDecoration:'none',cursor:"pointer",listStyle:'none', fontSize:'18px', fontWeight:"500", lineHeight:'38px',color:'#2727a3', }}>
                    <li >{res?.post_title}</li>
                
                 </ul>
                      </>
                    )
                  })
                 }

                 
                </div>
                        </>
                      )
                    })
                  }
    
                {/* <div className='col-lg-3 col-md-6 col-dm-12 mt-3'>
                 
                 <h5 style={{fontWeight:'700'}}>Top Desinations</h5>

                 <ul className='ps-0' style={{textDecoration:'none',listStyle:'none', fontSize:'18px', fontWeight:"500", lineHeight:'38px',color:'#2727a3', }}>
                    <li >Himachal</li>
                    <li>Mumbai</li>
                    <li>Uttar pradesh</li>
                    <li>Karnataka</li>
                    <li>Rajasthan</li>
                    <li>Gujarat</li>
                 </ul>
                </div>


                <div className='col-lg-3 col-md-6 col-dm-12 mt-3'>
                 
                 <h5 style={{fontWeight:'700'}}>Real Bharat</h5>

                 <ul className='ps-0' style={{textDecoration:'none',listStyle:'none', fontSize:'18px', fontWeight:"600", lineHeight:'38px',color:'#2727a3', }}>
                    <li >Geography</li>
                    <li>Culture</li>
                    <li>Art</li>
                    <li>Food</li>
                    <li>Traditions</li>
                    <li>Festivals</li>
                 </ul>
                </div>

                <div className='col-lg-3 col-md-6 col-dm-12 mt-3'>
                 
                 <h5 style={{fontWeight:'700'}}> Quick Links</h5>

                 <ul className='ps-0' style={{textDecoration:'none',listStyle:'none', fontSize:'18px', fontWeight:"600", lineHeight:'38px' ,color:'#2727a3',}}>
                    <li >About Ptvi</li>
                    <li>spirituality</li>
                    <li>Sitemap</li>
                    <li>Privacy Policy</li>
                    <li>Blogs</li>
                    <li>Travel Stories</li>
                 </ul>
                </div> */}
            </div>
        </div>
        </section>
    </div>
  )
}

export default Footer