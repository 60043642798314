import React, { useState, useEffect, useContext } from 'react'
import './Header.css'
import { useNavigate,NavLink } from 'react-router-dom';
import logo from "../../src/Component/Image/Logo.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Context from '../Context/Context'

const Header = () => {
  const { user, setUser } = useContext(Context);

  const [isOpen, setIsOpen] = useState(false);
  const [Data, setData] = useState([]);

  const Navigate = useNavigate();

  const toggleDropdown = (index) => {
    setIsOpen(index);
  };


  useEffect(() => {
    DataForHeader();
  }, []);


  const DataForHeader = () => {
    // const axios = require('axios');

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: 'https://kalyanmilan.online/api/getmenu',
      headers: {}
    };

    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data), "hhhhhhhhhhh==========>");
        // let data = response.data
        setData(response?.data?.data)
      })
      .catch((error) => {
        console.log(error);
      });

  }



  return (
    <>

      <div className='container-fluid '>
        <div className='row'>
          <div className='col-lg-12 col-md-12 col-sm-12 p-0'>
            <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top">
              <div class="container-fluid">
                <img src={logo} style={{ width: "10%", height: 70,objectFit: "cover",cursor:"pointer"}} onClick={()=>{Navigate(`/Home/46`)}}/>
                {/* <a class="navbar-brand" href="/">Logo</a> */}
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul class="navbar-nav m-auto mb-2 mb-lg-0">
                    

                    {
                      Data?.slice(0, 5).map((res, index) => {
                        return (
                          <React.Fragment key={index}>
                            <li className="nav-item dropdown" onMouseEnter={()=>toggleDropdown(index)} onMouseLeave={()=>toggleDropdown()}>
                              <a className="nav-link" role="button" aria-expanded={isOpen} style={{textTransform:"capitalize"}}>
                                {res?.name} <FontAwesomeIcon icon={isOpen===index  ? faAngleUp : faAngleDown} />
                              </a>
                              <ul className={`dropdown-menu ${isOpen===index ? 'show' : ''}`}>
                                {
                                  res?.onlyposts?.map((res) => {
                                    return (
                                      <>
                                        <li style={{cursor:"pointer"}} onClick={()=>{Navigate(`/${res?.post_slug}`);
                                          setUser(true)
                                        }}><h6 className="dropdown-item">{res?.post_name}</h6></li>
                                      </>
                                    )
                                  })
                                }

                              </ul>
                            </li>
                          </React.Fragment>
                        );
                      })
                    }

                    <NavLink to='/blogs'> 
                        <li class="nav-item">
                          <a class="nav-link" href="#">Blogs</a>
                        </li>
                    </NavLink>
    
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>

    </>
  )
}

export default Header