import React, { useContext, useEffect, useState } from 'react';
import { Routes, Route, } from 'react-router-dom';
import Beast from './Component/Beast';
import BaseCamp from './Component/BaseCamp';
import Explore from './Component/Explore';
import TripPlanner from './Component/TripPlanner';
import Context from './Context/Context';
import Home from './Component/Home';

function App() {
  const [slugType, setSlugType] = useState('');
  const { user, setUser } = useContext(Context);

  // const  navigate = useNavigation();
  useEffect(() => {
    const checkSlugType = async () => {
      const slug = window.location.pathname.split('/').pop();
      const URL = `https://kalyanmilan.online/api/checkSlug/${slug}`;

      try {
        const response = await fetch(URL);
        const data = await response.json();
        console.log(data,"datat")
        setSlugType(data.type);
      } catch (error) {
        console.error('Error checking slug type:', error);
      }
    };

    checkSlugType();
    setUser(false);
  }, [user]);

  useEffect(() => {
    const handleBackButton = () => {
      // window.history.back(); // Navigate back in history when the user clicks the back button
      setUser(true);
    };

    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);



  let componentToShow;
  if (slugType === 'post') {
    componentToShow = <BaseCamp />;
  } else if (slugType === 'base_category') {
    componentToShow = <TripPlanner />;
  } else {
    // Handle case where slug type is not recognized
    // You might want to redirect to a default page or show an error message
    // componentToShow = <div>No component found for this slug type.</div>;
  }

  return (
    <Routes>
      <Route path="/Home/:id" element={<Home />} />
      <Route path="/:id" element={componentToShow} />
      <Route path="/:id/:id" element={componentToShow} />
      <Route path="/blogs" element={<Explore />} />
      <Route path="/blogs/:id" element={<Beast />} />   
    </Routes>
  );
}

export default App;
