import React, { useEffect, useState } from 'react'
import '../App.css'
import Header from '../Component/Header'
import bhalu from '../Component/Image/Voyageurs.jpg'
import Footer from './Footer'
import { useNavigate, useParams } from 'react-router-dom'

const Beast = ({ }) => {


    const { id } = useParams();

    const navigate = useNavigate();
   const [recentTranding, setRecentTranding] = useState([])
    const [Data, setData] = useState();
    const [recentPost, setRecentPost] = useState([])
    const [showFullContent, setShowFullContent] = useState(false);

    const toggleContent = () => {
        setShowFullContent(!showFullContent);
    };

    useEffect(() => {
        Get_blogs();
    }, []);

    const truncateText = (text, maxLength) => {
        if (!text || text.length <= maxLength) {
            return text;
        }
        const truncated = text.substr(0, maxLength);
        const lastSpace = truncated.lastIndexOf(' ');
        return lastSpace === -1 ? truncated : truncated.substr(0, lastSpace) + '...';
    };


    const Get_blogs = () => {
        const requestOptions = {
            method: "GET",
            redirect: "follow"
        };

        fetch(`https://kalyanmilan.online/api/blogs/${id}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log(result);
                setData(result?.data);
                setRecentPost(result?.recent_post);
                setRecentTranding(result?.trending_stories?.posts)
                window.scrollTo(0, 0);

            })
            .catch((error) => console.error(error));
    }


    const divStyle = {
        backgroundImage: `url(${Data?.images?.length>0?Data?.images[0]?.img_path:""})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '300px', // Adjust height as needed
        display: 'flex',
        alignItems: 'center', // Center vertically
        justifyContent: 'center', // Center horizontally
        position: 'relative', // Ensure absolute positioned elements are relative to this div
        color: '#fff', // Text color
    };
    

    function extractParagraphs(htmlContent) {
        const paragraphRegex = /<p>(.*?)<\/p>/g;
        const paragraphs = [];
        let match;

        while ((match = paragraphRegex.exec(htmlContent)) !== null) {
            paragraphs.push(match[1]);
        }

        return paragraphs;
    }

    function getFirstAndSecondParagraphs(htmlContent) {
        const paragraphs = extractParagraphs(htmlContent);
        const firstParagraph = paragraphs[0] || '';
        const secondParagraph = paragraphs[1] || '';

        return firstParagraph;
    }


    return (
        <>

            <Header />

            <div className='container-fluid mt-5'>
                <div className='row'>
                    <div className='col-lg-12 col-md-12 col-sm-12 p-0'>
                        <div className='' style={divStyle}>
                            <h1 className='text-center hhhh' style={{ position: 'absolute', bottom: '5%', color: '#fff' }}>{Data?.title}</h1>
                        </div>
                    </div>
                </div>
            </div>




            <section>
                <div className='container mt-5'>
                    <div className='row'>
                        <div className='col-lg-8 col-md-8 col-sm-12'>
                            <span style={{ fontSize: '17px', fontWeight: '600' }}>24 Nov 2023, 05:48 PM</span>


                            <h3 className='pt-2' style={{ fontWeight: '600' }}>{Data?.title}</h3>

                            <span style={{ color: 'gray', fontSize: '19px', fontWeight: '500', }} dangerouslySetInnerHTML={{ __html: Data?.description }} />


                            {
                                Data?.posts?.map((res) => {
                                    return (
                                        <>
                                            <div className='mt-5'>
                                                <h3 className='pt-2' style={{ fontWeight: '600' }}>{res.post_title}</h3>

                                                <span style={{ color: 'gray', fontSize: '19px', fontWeight: '500', }} >{getFirstAndSecondParagraphs(res.post_up_fst_desc)}</span>

                                                <div className='my-3'>
                                                    <img src={res?.images[0]?.img_path} alt='image' style={{ width: '100%', height: '60vh', borderRadius: '8px' }} />


                                                    <h5 className='mt-3'>Read More : <span style={{ textDecoration: 'underline', color: 'blue' }} onClick={() => { navigate(`/${res.post_slug}`) }}>{res?.post_slug} travel guide</span></h5>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                            }


                        </div>



                        <div className='col-lg-4 col-md-4 col-sm-12 hbb ps-5'>

                            <div className='card mt-4'>
                                <div className='card-header border-0' style={{ backgroundColor: '#bdbdbd' }}>
                                    <h5 className='text-center'>Recent Posts</h5>
                                </div>
                                <div className='card-body' style={{ backgroundColor: '#e5e5e5' }}>
                                    {
                                        recentPost?.map((res) => {
                                            return (
                                                <>
                                                    <div className='row my-3' style={{ borderBottom: '1px solid #000' }}>
                                                        <div className='col-4 mb-3'>
                                                            <img src={res?.images[0]?.img_path} alt='image' style={{ width: '100%', borderRadius: '8px',height:60}} />

                                                        </div>
                                                        <div className='col-8 mb-3'>
                                                            <sapn style={{ color: 'gray', fontSize: '16px', fontWeight: '500', }}> {res?.post_title} </sapn>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })
                                    }
                                   
                                </div>
                            </div>


                            <div className='card mt-4'>
                                <div className='card-header border-0' style={{ backgroundColor: '#bdbdbd' }}>
                                    <h5 className='text-center'>Trending Stories
                                    </h5>
                                </div>
                                <div className='card-body' style={{ backgroundColor: '#e5e5e5' }}>
                                    {
                                        recentTranding?.map((res) => {
                                            return (
                                                <>
                                                    <div className='row my-3' style={{ borderBottom: '1px solid #000' }}>
                                                        <div className='col-4 mb-3'>
                                                            <img src={res?.images[0]?.img_path} alt='image' style={{ width: '100%', borderRadius: '8px',height:60 }} />

                                                        </div>
                                                        <div className='col-8 mb-3'>
                                                            <sapn style={{ color: 'gray', fontSize: '16px', fontWeight: '500', }}> {res?.post_title} </sapn>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })
                                    }

                                    {/* <div className='row my-3' style={{borderBottom:'1px solid #000'}}>
                                    <div className='col-4 mb-3'>
                                    <img src={bhalu} alt='image' style={{width:'100%', borderRadius:'8px'}}/>

                                    </div>
                                    <div className='col-8 mb-3'>
                                    <sapn style={{color:'gray',fontSize:'16px', fontWeight:'500',}}> Lorem Ipsum is simply dummy text of the printing and typesetting industry.    </sapn>
                                    </div>
                                </div>
                                <div className='row my-3' style={{borderBottom:'1px solid #000'}}>
                                    <div className='col-4 mb-3'>
                                    <img src={bhalu} alt='image' style={{width:'100%', borderRadius:'8px'}}/>

                                    </div>
                                    <div className='col-8 mb-3'>
                                    <sapn style={{color:'gray',fontSize:'16px', fontWeight:'500',}}> Lorem Ipsum is simply dummy text of the printing and typesetting industry.    </sapn>
                                    </div>
                                </div>
                                <div className='row my-3' style={{borderBottom:'1px solid #000'}}>
                                    <div className='col-4 mb-3'>
                                    <img src={bhalu} alt='image' style={{width:'100%', borderRadius:'8px'}}/>

                                    </div>
                                    <div className='col-8 mb-3'>
                                    <sapn style={{color:'gray',fontSize:'16px', fontWeight:'500',}}> Lorem Ipsum is simply dummy text of the printing and typesetting industry.    </sapn>
                                    </div>
                                </div>
                                <div className='row my-3' style={{borderBottom:'1px solid #000'}}>
                                    <div className='col-4 mb-3'>
                                    <img src={bhalu} alt='image' style={{width:'100%', borderRadius:'8px'}}/>

                                    </div>
                                    <div className='col-8 mb-3'>
                                    <sapn style={{color:'gray',fontSize:'16px', fontWeight:'500',}}> Lorem Ipsum is simply dummy text of the printing and typesetting industry.    </sapn>
                                    </div>
                                </div> */}
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Beast